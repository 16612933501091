<template>
  <div class="PointDeduction">
    <h2 class="title_date">{{$t('User.Orders.Pages.PointDeduction')}}</h2>
    <div class="content">
      <p class="allPoint">
        {{$t('Cart.Checkout.Pages.PointNow')}}：<span class="num">{{ point }}</span>
      </p>
      <div class="input">
        <div class="inputBox">
          <has-icon-input
            class="myInput"
            :placeholder="$t('Cart.Checkout.Pages.PointInput')"
            type="number"
            v-model="usePoint"
          >
          </has-icon-input>
          <div class="use btn" @click="gouse">
            <span>{{$t('Cart.Checkout.Use')}}</span>
          </div>
          <div class="cancel btn" @click="cancel">
            <span>{{$t('User.Orders.Button.Cancel')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HasIconInput from "common/input/HasIconInput";
export default {
  name: "PointDeduction",
  data() {
    return {
      // 输入的优惠劵号码
      usePoint: "",
    };
  },
  created() {},
  computed: {},
  components: {
    HasIconInput,
  },

  methods: {
    gouse() {
      if (this.usePoint == "") return;

      let point = parseInt(this.point);
      let use = parseInt(this.usePoint);
      if (use > point) {
        this.$message("error", this.$t('User.Point.Pages.LackPoint'));
        return;
      } else {
        this.$message("success",this.$t('Common.Msg.Success'));
        this.$emit("usePoint", use);
      }
    },

    cancel() {
      this.usePoint = "";
      this.$emit("usePoint", "0");
    },
  },
  props: {
    point: [String, Number],
  },
};
</script>
<style lang='scss' scoped>

.PointDeduction {
  font-size: 0.75rem;
  color: var(--text-color-minor);
  padding-top: 0.875rem;
  border-bottom: 1px solid var(--border-color-default);
  .title_date {
    font-size: 0.875rem;
    color: var(--text-color-default);
  }
  .content {
    padding: 1rem 0;
    .allPoint {
      margin-bottom: 0.75rem;
      .num {
        color: var(--red-color);
      }
    }
    .input {
      .inputBox {
        display: flex;
        align-items: center;

        .myInput {
          width: 40%;
        }

        .btn {
          padding: 0 2rem;
          cursor: pointer;
          background-color: var(--red-color);
          color: white;
          height: 60px;
          line-height: 60px;
          box-sizing: border-box;
        }

        .cancel {
          background-color: #d3d3d3;
        }
      }
    }
  }
}
</style>
