<template>
  <div class="Discounts">
    <h2 class="title_buyInfo">
    {{$t('Cart.Checkout.Pages.Coupons')}}<i
        class="iconfont"
        :class="{
          'icon-jiantou-copy-copy': isOpen,
          'icon-jiantou-copy-copy-copy': !isOpen,
        }"
        @click="isOpen = !isOpen"
      ></i>
    </h2>
    <div class="main" v-if="!isOpen">
      <has-icon-input
        class="btnBox"
        :issetRighticonBtn="true"
        border="1px solid #f6f6f6"
        :placeholder="$t('Cart.Checkout.Pages.EnterCoupon')"
        v-model="inputcoupon"
        backgroundColor="white"
      >
        <template v-slot:rightBtn>
          <div class="useBtn" @click="InputBtnClick">
            <p>{{$t('Cart.Checkout.Use')}}</p>
          </div>
        </template>
      </has-icon-input>
      <div class="mnav">
        <my-nav>
          <my-nav-item
            @click.native="choose = index"
            :name="item"
            v-for="(item, index) in navMenu"
            :active="choose == index"
            :key="item"
          ></my-nav-item>
        </my-nav>
      </div>

      <div class="coupon">
        <!-- 优惠劵 -->
        <discount-item
          class="couponitem"
          v-for="(item, index) in CouponList"
          @click.native="chooseOne(index, item)"
          :key="item.uc_id"
          :listData="item"
          :ischoose="chooseCoupon == index"
        ></discount-item>

        <empty
          v-if="CouponList.length == 0"
          :decripe="$t('Cart.Checkout.Pages.NoValidCoupon')+'!'"
          padding="5rem 0"
        ></empty>
      </div>

      <!-- <div class="discount">
        <span class="title">{{$t('Cart.Checkout.Pages.BalanceDeduction')}}：</span>
        <span class="price">{{ total.discount_text }}</span>
      </div> -->
    </div>
  </div>
</template>
<script>
import { MyNav, MyNavItem } from "common/mynav";
import DiscountItem from "content/discountItem/DiscountItem";
import HasIconInput from "common/input/HasIconInput";
import Empty from "common/empty/Empty";
import Cart from "@/model/Cart";
export default {
  name: "Discounts",
  data() {
    return {
      navMenu: [this.$t('Cart.Checkout.Pages.CouponCode')/* , this.$t('Cart.Checkout.Pages.Point') */],
      //选择的分类
      choose: 0,

      //选择的优惠劵
      chooseCoupon: -1,

      // 是否折起列表
      isOpen: true,

      //输入的优惠劵号码
      inputcoupon: "",
    };
  },
  props: {
    CouponList: {
      type: Array,
    },

    total: {
      type: Object,
    },
  },

  methods: {
    //选择了一个优惠劵
    chooseOne(index, data) {
      //如果选择已经选择的优惠劵，则视为取消
      if (this.chooseCoupon == index) {
        this.chooseCoupon = -1;
        return this.$emit("chooseCoupon", null);
      }
      this.useCoupon(data);

      this.chooseCoupon = index;
      this.$emit("chooseCoupon", data);
    },

    //使用优惠劵
    useCoupon(data, useHm) {
      Cart.useCoupon({
        data: {
          code: data.code,
          uc_id: data.uc_id,
          selgoods: sessionStorage.getItem("selgoods"),
        },
        succ: (res) => {
          // console.log(res);

          if (useHm) {
            //号码使用成功后，重新请求优惠劵列表,并且此时选中,传入id
            this.$parent.getCouponList(res.uc_id);
            this.inputcoupon = "";
          }
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
        
      });
    },

    //输入优惠劵号码后点击使用
    InputBtnClick() {
      if (this.inputcoupon == "") return;
      this.useCoupon(
        {
          code: this.inputcoupon,
          uc_id: "",
        },
        true
      );
    },
  },
  components: {
    MyNav,
    MyNavItem,
    DiscountItem,
    HasIconInput,
    Empty,
  },
  created() {},
};
</script>
<style lang='scss' scoped>

.Discounts {
  margin-top: 0.875rem;

  .title_buyInfo {
    font-size: 0.875rem;
    color: var(--text-color-default);
    margin-bottom: 0.875rem;
    i {
      color: var(--text-color-minor);
      margin-left: 0.5rem;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .main {
    border: 1px solid var(--border-color-default);
    .mnav {
      background-color: var(--bg-color-hint);
      margin-bottom: 0.875rem;
    }

    .coupon {
      width: 100%;
      box-sizing: border-box;
      padding-left: 0.875rem;
      padding-right: 0.875rem;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;

      .couponitem {
        width: 23%;
        margin-bottom: 1rem;
        margin-right: 1rem;
      }
    }

    .discount {
      background-color: var(--bg-color-hint);
      padding: 0.875rem;
      box-sizing: border-box;
      font-size: 0.875rem;
      width: 100%;
      font-weight: 600;
      .title {
        color: var(--text-color-default);
      }

      .price {
        color: var( --red-color)
      }
    }
  }
}
.btnBox {
  width: 40%;
  margin:10px ;
  .useBtn {
    margin-right: 10px;
    p {
      font-size: 0.875rem;
      color: var(--text-color-default);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 5rem;
    height: 70%;
    border-radius: 5px;
    border: 1px solid var(--border-color-default);
  }
}
</style>