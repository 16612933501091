<template>
  <tr class="trItem">
    <td>
      <img
        v-lazy="item.image"
        alt=""
        class="productImg mouse_jt"
        @click="goGoodDetil(item.product_id)"
      />
    </td>
    <td>
      <p class="productName">
        {{$cl('name',item,$i18n.locale)}}
      </p>
    </td>
    <td>
      <p class="price">
        {{ item.price_text }}
      </p>
    </td>
    <td>
      <div class="quantity">
        <div class="countNum">
          <my-icon
            :Iconconfig="Iconconfig"
            chooseIcon="icon-jianhao"
            @iconBtnClick="sub(item, 'subOne',1)"
          ></my-icon>
          <!-- <span
            class="num"
            @dblclick="isShowInput = !isShowInput"
            v-show="!isShowInput"
            >{{ item.quantity }}</span
          > -->
          <input
            class="inputNum"
            type="text"
            ref="my_input"
            :value="item.quantity"
            @blur="changeNum($event, $refs.my_input)"
            @keyup.enter="changeNum($event, $refs.my_input)"
          />
          <my-icon
            :Iconconfig="Iconconfig"
            chooseIcon="icon-add"
            @iconBtnClick="add(item)"
          ></my-icon>
        </div>
      </div>
    </td>
    <td>
      <p class="productTotal">{{ item.subtotal_text }}</p>
    </td>
    <td>
      <div class="delBtn" v-if="!isHiddenDelBtn">
        <i
          class="iconfont icon-guanbi"
          @click="delProduct(item.cart_ident)"
        ></i>
      </div>
    </td>
  </tr>
</template>
<script>
import MyIcon from "common/icon/MyIcon";
import Cart from "@/model/Cart";
import debounce from "@/common/my_utils/network/debounce.js";
export default {
  name: "GoodDetilItem",
  data() {
    return {
      Iconconfig: {
        width: "1.5rem",
        height: "1.5rem",
        lineHeight: "1.5rem",
        backgroundColor: "#edeef5",
        color: "#272435",
        fontSize: "0.75rem",
        border: "none",
      },


    };
  },
  props: {
    item: {
      type: Object,
    },

    // 是否隐藏删除按钮
    isHiddenDelBtn:{
      type:Boolean,
      default:false
    }
  },
  components: {
    MyIcon,
  },
  methods: {
    //输入完失去焦点，重新更新商品
    changeNum(e, my_input) {
      // 如果当前输入的值和之前的值一样则不做处理
      if (my_input._value.toString() == my_input.value.toString()) {
        return;
      }
      let newNum = e.target.value;
      // 检查数字是否合法
      if (newNum == "") {
        e.target.value = my_input._value;
        return this.$message("error",this.$t('Common.Msg.InputError'));
      }
      let reg = /[^(0-9)]/;
      if (reg.test(newNum)) {
        e.target.value = my_input._value;
        return this.$message("error", this.$t('Common.Msg.InputError'));
      } else {
        this.sub(this.item, "update", parseInt(newNum));
      }
    },
    //跳转到商品详情页
    goGoodDetil(product_id) {
      this.$router.push({
        path: "/product/index",
        query: {
          product_id,
        },
      });
    },

    //增加数量
    add:debounce(function([all]) {
      
        this.$parent.$parent.isshow = true
      Cart.Add({
        data: {
          goods_id: all.goods_id,
          product_id: all.product_id,
          num: 1,
          type: "goods",
        },
        succ: (res, mes) => {
          //将返回的列表数据添加到all中
          all.network_data = res.carts;
          // this.$message("success", mes.msg);
          this.updateList("add", all);
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
        
      });
    },300),

    //减少数量,subOne数量减1
    sub:debounce(function([all, met, newNum]) {
     
      let NewQuantity;
      //数量减一
      if (met === "subOne") {
        all.quantity > 1 ? (NewQuantity = all.quantity - 1) : (NewQuantity = 1);
      } else if (met === "update") {
        NewQuantity = newNum;
      }

        this.$parent.$parent.isshow = true
      Cart.Update({
        data: {
          cart_ident: all.cart_ident,
          selgoods:JSON.stringify([]),
          quantity: NewQuantity,
        },
        succ: (res, mes) => {
          all.network_data = res.carts;
          // this.$message("success", mes.msg);
          this.updateList("subOne", all);
        },
        fail: (res, all) => {
           this.$parent.$parent.isshow = false
          this.$message("error", all.msg);
        },
        
      });
    },300),

    //删除一个商品
    delProduct(cart_ident) {
      this.$confirm("danger", this.$t('Common.Confirm.Title'), this.$t('Cart.Msg.DeleteCartOne'), true,null,this)
        .then((ele) => {
          Cart.Delete({
            data: {
              cart_ident: JSON.stringify([cart_ident]),
              selgoods: "[]",
            },
            succ: (res, mes) => {
              ele.close();
              this.$message("success", mes.msg);
              this.updateList("del", cart_ident);
            },
            fail: (res, all) => {
              ele.close();
              this.$message("error", all.msg);
            },
            
          });
        })
        .catch(() => {});
    },

    //查找data和selgoods中相同key
    findGood(selgoods, data, key, needUpdate) {
      selgoods = selgoods.map((item) => {
        //判断当前编辑的商品
        if (item[key] === data[key]) {
          item[needUpdate] = data.network_data.find((value) => {
            return value[key] === data[key];
          })[needUpdate];
        }
        return item;
      });

      return selgoods;
    },

    //修改商品数据后应该更新列表数据
    updateList(type, data) {
      let selgoods = JSON.parse(localStorage.getItem("selgoods"));

      //如果是增加了商品
      if (type === "add") {
        selgoods = this.findGood(selgoods, data, "cart_ident", "quantity");
      }

      //如果是减少了一个商品
      if (type === "subOne") {
        selgoods = this.findGood(selgoods, data, "cart_ident", "quantity");
      }

      //如果删除了一个商品
      if (type === "del") {
        selgoods = selgoods.filter((item) => {
          return item.cart_ident != data;
        });
      }

      //更新购物车
      this.$store
        .dispatch("getCart")
        .then((res) => {})
        .catch((err) => {
          this.$message("error", err);
        });

      localStorage.setItem("selgoods", JSON.stringify(selgoods));
      //重新请求列表数据
      this.$emit("reGetgoodlist");
    },
  },
  created() {},
};
</script>
<style lang='scss' scoped>

.trItem {
  td {
    padding: 0.75rem;
    font-size: 0.875rem;
    color: var(--text-color-title);
    border-bottom: 1px solid var(--border-color-default);
    .productImg {
      max-width: 4.375rem;
    }

    .quantity {
      .countNum {
        display: flex;
        align-items: center;
        .num {
          width: 2rem;
          box-sizing: border-box;
          padding: 0 0.4rem;
          text-align: center;
        }
      }
    }
  }
}

.inputNum {
  border: none;
  width: 2.5rem;
  box-sizing: border-box;
  padding: 0 0.4rem;
  outline: none;
  text-align: center;
}

.delBtn {
  height: 1.5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  i {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
  i:hover {
    background-color: var(--text-color-minor);
    color: white;
  }

  .loading {
    font-size: 1.5rem;
  }
}
</style>