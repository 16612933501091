<template>
  <div class="date">
    <h2 class="title_date">{{$t('Cart.Checkout.Pages.SelectShipDay')}}</h2>
    <div class="chooseDate">
      <el-date-picker
        v-model="date"
        value-format="yyyy-MM-dd"
        type="date"
        :placeholder="$t('Cart.Checkout.Pages.ShipDay')"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
  </div>
</template>
<script>
export default {
  name: "DeliveryDate",
  data() {
    return {
      isshowDialog: true,
      date: "",
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
    };
  },
  props: {
    checkout: {
      type: Object,
    },
  },
  computed: {},

  methods: {
    disabledDate(time) {
      let needStop = "";
      if (this.checkout != null) {
        if (time.getTime() <= Date.now()) {
          return true;
        }
        let arr = this.checkout.rest_weekday;
        for (let i = 0; i < arr.length; i++) {
          if (time.getDay() == parseInt(arr[i])) {
            return true;
          } else if (parseInt(arr[i]) == 7) {
            if (time.getDay() == 0) {
              return true;
            }
          }
        }
      } else {
        return time.getTime() <= Date.now() || time.getDay() == 0;
      }
    },
  },

  mounted() {},

  created() {},
  components: {},
};
</script>
<style lang='scss' scoped>

.date {
  font-size: 0.75rem;
  color: var(--text-color-minor);
  padding-top: 0.875rem;
  border-bottom: 1px solid var(--border-color-default);
  .title_date {
    font-size: 0.875rem;
    color: var(--text-color-default);
  }

  .chooseDate {
    margin: 0.75rem 0;
  }

  .content {
    max-width: 900px;
    padding: 1rem;
    .header {
      height: 30px;
      line-height: 30px;
      font-size: 0.875rem;
      display: flex;
      justify-content: space-between;
    }
  }

  .is-selected {
    color: #1989fa;
  }
}
</style>