<template>
  <div class="check">
    <div class="container">
      <def-loading
        :setStyle="{ position: 'fixed' }"
        :isshow="isshow"
      ></def-loading>

      <my-breadcrumb>
        <my-breadcrumb-item to="/">{{
          $t("Goods.Pages.Home")
        }}</my-breadcrumb-item>
        <my-breadcrumb-item to="/" :active="true" :isDisabled="true">{{
          $t("Cart.Pages.Checkout")
        }}</my-breadcrumb-item>
      </my-breadcrumb>

      <div class="main">
        <div class="title">
          <span>{{ $t("Cart.Checkout.WriteOrder") }}</span>
          <span class="backCart" @click="$router.replace('/cart/index')">{{
            $t("Cart.Pages.MyCart")
          }}</span>
        </div>
        <div class="buyInfo">
          <!-- 收货人信息 -->
          <user-info
            :AllAddr="AllAddr"
            @chooseNewAdd="chooseNewAdd"
            ref="user_info"
            :chooseAddr.sync="chooseAddr"
          ></user-info>

          <!-- 送货日期 -->
          <!-- <delivery-date
            :checkout="checkout"
            ref="deliveryDate"
          ></delivery-date> -->

          <!-- 配送方式 -->
          <delivery
            :shipping="shipping"
            @chooseShipping="chooseShipping"
            ref="delivery"
          ></delivery>

          <!-- 商品明细 -->
          <goods-detil
            :goodList="goodList"
            @changeGoodList="changeGoodList"
          ></goods-detil>

          <!-- 优惠信息 -->
          <!-- <discounts
            :CouponList="CouponList"
            @chooseCoupon="chooseCoupon"
            :total="total"
            ref="discounts"
          ></discounts> -->

          <!-- 积分抵扣 -->
          <!-- <point-deduction
            :point="point"
            @usePoint="isusePoint"
          ></point-deduction> -->

          <!-- 缺货处理 -->
          <stockout ref="stockout"></stockout>

          <!-- 留言信息 -->
          <message v-model="messageinfo"></message>

          <!-- 结算信息 -->
          <settle-info
            :total="total"
            v-if="AllAddr.length != 0 && priceIsOk"
            :chooseAddr="chooseAddr"
            @create="create"
          ></settle-info>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MyBreadcrumb, MyBreadcrumbItem } from "common/breadcrumb";
import {
  UserInfo,
  Delivery,
  GoodsDetil,
  Discounts,
  SettleInfo,
  Message,
  DeliveryDate,
  Stockout,
  PointDeduction,
} from "./child";
import User from "@/model/User.js";
import Cart from "@/model/Cart";
import Order from "@/model/Order";
export default {
  name: "Check",
  data() {
    return {
      //地址列表
      AllAddr: [],

      //配送列表
      shipping: [],

      //结算页信息，商品列表
      checkout: null,
      goodList: [],

      // 用户积分信息
      point: "",

      // 用户使用的积分
      usePoint: "0",

      //优惠劵列表
      CouponList: [],

      //结算信息
      total: {},

      //买家留言信息
      messageinfo: "",

      //当前选中的地址
      chooseAddr: {},

      //当前选择的配送方式
      chooseShip: {},

      //当前选择的优惠劵
      chooseCou: {},

      //购买清单
      selgoods: "",

      //是否达到起订金额
      priceIsOk: true,

      isshow: false,
    };
  },
  components: {
    MyBreadcrumb,
    MyBreadcrumbItem,
    UserInfo,
    Delivery,
    GoodsDetil,
    Discounts,
    SettleInfo,
    Message,
    DeliveryDate,
    Stockout,
    PointDeduction,
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    // 设置selgoods
    setSelgoods() {
      let selgoods = localStorage.getItem("selgoods");
      if (selgoods) {
        this.selgoods = selgoods;
      } else {
        this.selgoods = JSON.stringify([]);
      }
    },

    // 1.检测购物车有没有数据 2.如果本地没有selgoods，则需要在创建时在本地写入所有，如果为[]，则写入所有

    checkSelgoods() {
      let cart = this.$store.state.cart.cart.carts;
      let selgoods = localStorage.getItem("selgoods");
      if (selgoods && JSON.parse(selgoods).length != 0) {
        this.selgoods = selgoods;
        return true;
      } else {
        let arr = [];
        cart.forEach((item) => {
          let obj = {};
          obj.cart_ident = item.cart_ident;
          obj.quantity = item.quantity;
          arr.push(obj);
        });
        localStorage.setItem("selgoods", JSON.stringify(arr));
        return true;
      }
    },

    async init() {
      if (!this.checkSelgoods()) return;
      this.getCouponList();
      this.getCheckout();
      this.getPoint();
      try {
        await this.getAllAddr("frist");
        await this.getShipping();
        await this.getTotal();
      } catch (err) {
        console.log(err);
      }
    },

    //当前选择的地址
    async chooseNewAdd() {
      //修改选中地址后再次请求配送方式,再请求总价接口
      try {
        await this.getShipping();
        await this.getTotal();
      } catch (err) {
        console.log(err);
      }
    },

    //当前选择的配送方式
    chooseShipping(index) {
      this.chooseShip = this.shipping[index];
      this.getTotal();
    },

    //当前选择的优惠劵
    chooseCoupon(data) {
      this.chooseCou = data;
      this.getTotal();
    },

    // 使用积分抵扣后请求总价接口
    isusePoint(e) {
      this.usePoint = e;
      this.getTotal();
    },

    //修改了商品列表
    async changeGoodList() {
      try {
        await this.getCheckout();
        await this.getTotal();
      } catch (err) {
        console.log(err);
      }
    },

    // 请求地址列表
    getAllAddr(isFrist) {
      if (isFrist == "frist") {
        this.AllAddr = [];
        this.shipping = [];
        this.chooseShip = {};
        this.chooseAddr = {};
      }

      this.setSelgoods();

      return User.Addr.List({
        data: {},
        succ: (res) => {
          this.AllAddr = res.address;

          //将已选的的地址重置一下
          if (this.chooseAddr.addr_id) {
            this.AllAddr.forEach((item) => {
              if (item.addr_id == this.chooseAddr.addr_id) {
                this.chooseAddr = item;
              }
            });
          }

          //如果是首次请求则选中第一个地址
          if (isFrist == "frist") {
            this.chooseAddr = this.AllAddr[0];
          }

          //如果是顶部点击设为默认，则需要更新已选项的属性
          if (isFrist == "isTop") {
            this.chooseAddr = this.AllAddr[0];
          }

          //如果是编辑地址信息时触发的请求地址列表且当前编辑的与已选的一致
          if (isFrist == "isChoose") {
            this.chooseAddr = this.AllAddr.filter((item) => {
              return item.addr_id == this.chooseAddr.addr_id;
            })[0];
          }
        },
        fail: (res, all) => {
          this.isshow = false;
          this.$message("error", all.msg);
          if ((all.msg = "没有设置地址")) {
            this.AllAddr = [];
          }
        },
      });
    },

    // 获取积分信息
    getPoint() {
      User.Point.List({
        data: {
          page: 1,
          limit: 10,
          type: "active",
        },
        succ: (res) => {
          this.point = res.point;
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },

    //获取配送方式
    getShipping() {
      if (this.AllAddr.length == 0) {
        return;
      }

      this.setSelgoods();
      return Cart.Shipping({
        data: {
          addr_id: this.chooseAddr.addr_id,
          selgoods: this.selgoods,
          extend: '{"type":"goods"}',
          is_fastbuy: "0",
        },
        succ: (res) => {
          this.shipping = res.data;
          //默认选择第一个
          this.chooseShip = this.shipping[0];
          //重置配送组件中的选择项
          this.$refs.delivery.change();
        },
        fail: (res, all) => {
          this.isshow = false;
          this.$message("error", all.msg);
        },
      });
    },

    //获取结算页信息
    getCheckout() {
      this.setSelgoods();
      return Cart.Checkout({
        data: {
          selgoods: this.selgoods,
          is_fastbuy: "0",
        },
        succ: (res) => {
          this.checkout = res;
          this.goodList = res.carts;
        },
        fail: (res, all) => {
          this.isshow = false;
          this.$message("error", all.msg);
        },
      });
    },

    //获取优惠券列表
    getCouponList(id) {
      this.setSelgoods();
      return Cart.getCouponList({
        data: {
          selgoods: this.selgoods,
          is_fastbuy: "0",
          extend: "",
        },
        succ: (res) => {
          this.CouponList = res.list;

          if (!!id) {
            //使用优惠劵号码后找到该优惠劵
            let usecou = this.CouponList.findIndex((item) => {
              return item.uc_id == id;
            });
            this.$refs.discounts.chooseOne(usecou, this.CouponList[usecou]);
          }
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },

    //获取结算信息
    getTotal() {
      if (this.AllAddr.length == 0) {
        this.isshow = false;
        return;
      }
      this.setSelgoods();
      return Cart.Total({
        data: {
          shipping_id: this.chooseShip?.list?.[0]?.shipping_id,
          payment: "0",
          addr_id: this.chooseAddr.addr_id,
          selgoods: this.selgoods,
          is_fastbuy: "0",
          extend: "",
          uc_id: this.chooseCou?.uc_id,
          use_balance: "",
          use_point: this.usePoint,
          baowen_box: "true",
        },
        succ: (res) => {
          this.total = res.total;
          this.priceIsOk = true;
          this.isshow = false;
        },
        fail: (res, all) => {
          this.isshow = false;
          this.priceIsOk = false;
          this.$message("error", all.msg);
        },
      });
    },

    // 检测送货日期
    checkDelivery() {
      return true; //不是本地的超时，不用选送货日期
      let res = this.$refs.deliveryDate.date;
      if (res != "") {
        return Date.parse(res) / 1000;
      } else {
        return false;
      }
    },

    create() {
      if (!this.checkDelivery()) {
        return this.$message(
          "error",
          this.$t("Cart.Checkout.Pages.SelectShipDay")
        );
      }

      this.setSelgoods();

      Order.Create({
        data: {
          addr_id: this.chooseAddr.addr_id,
          selgoods: this.selgoods,
          shipping_id: this.chooseShip?.list?.[0]?.shipping_id,
          payment: "0",
          source: "PC",
          memo: this.messageinfo,
          is_pintuan: "",
          pintuan_id: "",
          pintuan_num: "",
          free_pintuan_id: "",
          originator_free: "undefined",
          formId: "undefined",
          md5_cart: "b412470055d8e5664a19e9e72c75eb2d",
          extend: "",
          is_fastbuy: "0",
          uc_id: this.chooseCou?.uc_id,
          use_point: this.usePoint,
          ship_day: this.checkDelivery(),
          outofstoredo: this.$refs.stockout.choose,
          baowen_box: "true",
        },
        succ: (res) => {
          this.$router.push({
            path: "/paycenter/index",
            query: {
              order_id: res.order_id,
            },
          });
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.check {
  padding-top: 1.5rem;
  background-color: white;
  padding-bottom: 2rem;
  .container {
    .main {
      .title {
        color: var(---text-color-title);
        font-size: 1.15rem;
        margin: 1.5rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .backCart {
          font-size: 0.75rem;
          cursor: pointer;
          color: var(--red-color);
        }
      }
      .buyInfo {
        border: 1px solid var(--border-color-default);
        padding: 1rem;
      }
    }
  }
}
</style>