<template>
  <div class="DiscountItem" :class="{ valid: ischoose,noUse:noUse}" @mouseenter="showBtn=true" @mouseleave="showBtn=false">
    <div class="item">
      <div class="top">
        <p class="money">{{$cl('coupon_name',listData,$i18n.locale)}}</p>
        <!-- <p class="condition">满1000</p> -->
      </div>
      <div class="bottom">
        <p>{{$t('User.Coupons.Pages.ExpiryDate')}}：{{ settime }}</p>
      </div>
    </div>
    <div class="group">
      <!-- <span>[东券]</span>
      <span>[限品类]</span> -->

      <i class="icon iconfont icon-in_gouxuan"></i>
    </div>



  <transition name="btn">
  

    <!-- 使用和删除 -->
    <div class="useOrdel" v-if="showBtn && isOpenBtn && !noUse">
      <div class="useBtn btn">
        {{$t('Cart.Checkout.Use')}}
      </div>
      <div class="delBtn btn">
        {{$t('Cart.Delete')}}
      </div>
    </div>
  </transition>

    <!-- <p class="hint"><i class="iconfont icon-jinggao"></i>所结算商品中没有符合条件的商品</p> -->
  </div>
</template>
<script>
export default {
  name: "DiscountItem",
  data() {
    return {

      val:!this.isOpenBtn
    };
  },
  computed:{
    showBtn:{
      get(){
        return this.val
      },
      set(val){
        this.val = val
      }
    },

    //适配过期时间
    settime(){
      let reg = /^[0-9]+$/
      if(reg.test(this.listData.end_time)){
        // return this.listData.end_time_text.split(' ')[0]
        return my_utils.changeTimestamp(this.listData.end_time)
      }else{
        return this.listData.end_time
      }
    }

  },
  created() {},
  props: {
    listData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    ischoose: {
      type: Boolean,
    },

    // 是否开启删除和使用按钮
    isOpenBtn:{
      type:Boolean,
      default:false
    },

    //当前的优惠劵是否该无法使用
    noUse:{
      type:Boolean,
      default:false
    }
  },
};
</script>
<style lang='scss' scoped>

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.btn-enter-active {
  transition: all .3s;
}
.btn-leave-active {
  transition: all .3s ;
}
.btn-enter, .btn-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(-800px);
  opacity: 0;
}


.DiscountItem {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  .useOrdel{
    position: absolute;
    z-index: 6;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .btn{
      text-align: center;
      width: 5.5rem;
      margin:0 0.5rem;
      padding:0.5rem 1.2rem;
      font-size: 0.75rem;
      border-radius: 1rem;
      box-sizing: border-box;
      border:1px solid white;
      color:white;

      &:hover{
        background-color: white;
        color:#666;
      }
      
    }
  }
  .item {
    width: 100%;
    background-image: url("~assets/img/discounts/effective.jpg");
    background-size: 10%;
    background-repeat: repeat-x;
    .top {
      padding-top: 1rem;
      padding-left: 0.875rem;
      padding-right: 0.875rem;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .money {
        font-size: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 65%;
      }
      .condition {
        font-size: 0.875rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 30%;
      }
    }
    .bottom {
      p {
        padding: 0.75rem;
        font-size: 0.875rem;
        // color: #b4b2b3;
        color: white;
      }
    }
  }
  .group {
    position: relative;
    padding: 0.875rem;
    color: #d0d0d0;
    border: 1px solid #d0d0d0;
    span {
      margin-right: 0.5rem;
    }

    .icon {
      position: absolute;
      opacity: 0;
      bottom: 0;
      right: 0;
    }
  }

  .hint {
    color: #d0d0d0;
    font-size: 0.75rem;
    line-height: 2;
  }
}

.valid {
  .item {
    background-image: url("~assets/img/discounts/valid.jpg");
    .bottom {
      p {
        color: white;
      }
    }
  }
  .group {
    position: relative;
    color: var(--red-color);
    border: 1px solid var(--red-color);
    .icon {
      opacity: 1;
      font-size: 2rem;
    }
  }
}

.noUse{
  .item{
    background-image: url("~assets/img/discounts/nouse.jpg");
  }
}
</style>