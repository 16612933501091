<template>
  <div class="GoodsDetil">
    <h2 class="title_buyInfo">{{ $t("Goods.Pages.Gallerylist") }}</h2>
    <div class="main" ref="main">
      <table class="cartTable" border="0" cellpadding="0" cellspacing="0">
        <thead class="tableHead">
          <tr>
            <th></th>
            <th>{{ $t("Goods.Pages.ProductName") }}</th>
            <th>{{ $t("Home.Pages.Price") }}</th>
            <th>{{ $t("Goods.Pages.Quant") }}</th>
            <th>{{ $t("User.Orders.Pages.LittleTotal") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="tableBody" ref="tableBody">
          <good-detil-item
            v-for="item in goodList"
            isHiddenDelBtn
            :key="item.cart_ident"
            :item="item"
            @reGetgoodlist="reGetgoodlist"
          ></good-detil-item>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import GoodDetilItem from "content/goodsDetilItem/GoodDetilItem";
export default {
  name: "GoodsDetil",
  data() {
    return {};
  },

  props: {
    goodList: {
      type: Array,
    },
  },
  created() {},
  methods: {
    reGetgoodlist() {
      this.$emit("changeGoodList");
    },

    open() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$refs.main.style.height = "400px";
      } else {
        this.$refs.main.style.height = "auto";
      }
    },
  },
  components: {
    GoodDetilItem,
  },
};
</script>
<style lang='scss' scoped>
.GoodsDetil {
  margin-top: 0.875rem;
  .title_buyInfo {
    font-size: 0.875rem;
    color: var(--text-color-default);
    i {
      color: var(--text-color-minor);
      margin-left: 0.5rem;
      &:hover {
        opacity: 0.9;
      }
    }
  }

  .cartTable {
    width: 100%;
    th,
    td {
      padding: 0.75rem;
      border-bottom: 1px solid var(--border-color-default);
    }
    .tableHead {
      tr {
        th {
          font-size: 0.75rem;
          color: var(--text-color-minor);
          text-align: left;
        }
      }
    }
  }
}

.main {
  max-height: 400px;
  overflow: auto;
  position: relative;
}
</style>