<template>
  <div class="stockout" style="display:none">
    <h2 class="title_date">{{$t('Cart.Checkout.Pages.DisposeStockout')}}</h2>
    <div class="content">
      <el-radio-group v-model="choose">
        <el-radio :label="0">{{$t('Stockout.Refund')}}</el-radio>
        <el-radio :label="1">{{$t('Stockout.AutoReplace')}}</el-radio>
        <el-radio :label="2">{{$t('Stockout.ClientReplace')}}</el-radio>
      </el-radio-group>
    </div>
  </div>
</template>
<script>

export default {
  name: "Stockout",
  data() {
    return {
      choose:0,
    };
  },
  created() {},
};
</script>
<style lang='scss' scoped>
.stockout {
  font-size: 0.75rem;
  color: var(--text-color-minor);
  padding-top: 0.875rem;
  border-bottom: 1px solid var(--border-color-default);
  .title_date {
    font-size: 0.875rem;
    color: var(--text-color-default);
  }
  .content{
      padding:1rem 0;
  }
}
</style>