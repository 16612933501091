<template>
   <div class="message">
            <div class="title">
              <h4>{{$t('Cart.Checkout.Pages.Message')}}</h4>
            </div>
            <textarea
              class="txt"
              cols="30"
              rows="10"
              v-model="model"
            >
            </textarea>
          </div>
</template>
<script>
export default {
    name:'message',

data(){
return {


}
},
computed:{
model:{
    get(){
        return this.value
    },
    set(value){
        this.$emit('input',value)
    }
}
},

created(){
},
methods:{

},
props:{
    value:{
        type:String
    }
}
}
</script>
<style lang='scss' scoped>


.message{
    margin-top:1.5rem;

    .title{
        h4{
            color:var(--text-color-default);
            font-size: 0.875rem;
        }
    }
    .txt{
        width: 100%;
       border:none;
       background-color:var(--bg-color-hint) ;
       margin-top:0.75rem;
       outline: none;
       padding:1rem;
       box-sizing: border-box;
       font-size:0.875rem;
       color:var(--text-color-minor);
       resize: none;
    }
}
</style>