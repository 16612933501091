<template>
  <div class="SettleInfo">
    <div class="totalPrice">
      <div class="priceBoxLeft">
        <p>
          <span class="num">{{ total.goods_num_quant }}</span
          >{{ $t("SettleInfo.Total") }}：
        </p>
        <p>{{ $t("Cart.Checkout.Pages.Shipping") }}：</p>
        <p>{{ $t("Product.Validation.Discounts") }}：</p>
        <!-- <p>{{ $t("Cart.Checkout.Pages.PointDeduction") }}：</p> -->
      </div>
      <div class="priceBoxRight">
        <p>{{ total.goods_price_text }}</p>
        <p>{{ total.shipping_fee_text }}</p>
        <p>-{{ total.discount_text ? total.discount_text : '$0.00' }}</p>
        <!-- <p>-{{ total.point_deduction_text }}</p> -->
      </div>
    </div>

    <div class="practical">
      <p class="top">
        {{ $t("SettleInfo.Payment") }}：<span class="price">{{
          total.total_price_text
        }}</span>
      </p>
      <p class="bottom">
        {{ $t("User.Address.Addr") }}：<span class="site">{{
          chooseAddr.addr_text
        }}</span>
        <span class="user">{{ $t("Cart.Checkout.ConsigneeInfo") }}：</span
        ><span class="name">{{ chooseAddr.ship_name }}</span
        ><span>{{ chooseAddr.ship_mobile }}</span>
      </p>
    </div>

    <div class="btnBox">
      <div class="btn" @click="create">
        {{ $t("Cart.Checkout.Pages.PlaceOrder") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SettleInfo",
  data() {
    return {};
  },
  created() {},
  props: {
    total: {
      type: Object,
      default: () => {},
    },
    chooseAddr: {
      type: Object,
    },
  },
  methods: {
    create() {
      this.$emit("create");
    },
  },
};
</script>
<style lang='scss' scoped>
.SettleInfo {
  margin-top: 1.25rem;
  .totalPrice {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid var(--border-color-default);
    padding-bottom: 1rem;
    padding-right: 1rem;
    .priceBoxLeft {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 3.5rem;
      p {
        font-size: 0.875rem;
        line-height: 2;
        color: var(--text-color-minor);
        span {
          color: var(--red-color);
          margin-right: 0.5rem;
          font-weight: 600;
        }
      }
    }
    .priceBoxRight {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      p {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 2;
        color: var(--text-color-minor);
      }
    }
  }

  .practical {
    padding-right: 1rem;
    background-color: var(--bg-color-hint);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .top {
      margin: 0.5rem 0;
      font-size: 0.875rem;
      color: var(--text-color-minor);
      .price {
        font-size: 1.25rem;
        font-weight: 600;
        color: var(--red-color);
        margin-left: 1.25rem;
      }
    }

    .bottom {
      color: var(--text-color-minor);
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
      .user {
        margin-left: 1rem;
      }

      .name {
        margin-right: 0.75rem;
      }
    }
  }

  .btnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    .btn {
      cursor: pointer;
      font-size: 1rem;
      padding: 0.75rem 1.25rem;
      background-color: var(--red-color);
      color: white;
      font-weight: 600;
      border-radius: 5px;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>