<template>
  <div class="userInfo">
    <div class="top">
      <h2 class="title_buyInfo">{{ $t("Cart.Checkout.ConsigneeInfo") }}</h2>
      <span class="addsite" @click="addsite">{{
        $t("Cart.Checkout.AddNewAddress")
      }}</span>
      <!-- 弹出框 -->
      <pop-up :isshow="isshowDialog" width="70vw">
        <div class="pop">
          <div class="header">
            <span>{{ popname }}</span>
            <i
              class="iconfont icon-guanbi close"
              @click="isshowDialog = !isshowDialog"
            ></i>
          </div>

          <div class="content_f">
            <!-- 用户名 -->
            <div class="addrItem">
              <div class="left">
                <span class="name"
                  ><strong>*</strong>{{ $t("User.Address.Name") }}：</span
                >
              </div>
              <div class="right">
                <has-icon-input
                  :height="50"
                  backgroundColor="#f6f6f6"
                  class="input"
                  v-model="userInfo.name"
                ></has-icon-input>
              </div>
            </div>
            <!-- 用户手机号 -->
            <div class="addrItem">
              <div class="left">
                <span class="name"
                  ><strong>*</strong>{{ $t("User.Address.Mobile") }}：</span
                >
              </div>
              <div class="right">
                <has-icon-input
                  :height="50"
                  backgroundColor="#f6f6f6"
                  class="input"
                  v-model="userInfo.mobile"
                ></has-icon-input>
              </div>
            </div>

            <!-- 城市选择器 -->
            <city-selection
              @changeCity="changeCity"
              @changeShipAreaCode="changeShipAreaCode"
              :defaultChoose="defaultChoose"
            />

            <!-- 用户省份 -->
            <div class="addrItem">
              <div class="left">
                <span class="name"
                  ><strong>*</strong>{{ $t("User.Address.Province") }}：</span
                >
              </div>
              <div class="right">
                <has-icon-input
                  :height="50"
                  backgroundColor="#f6f6f6"
                  class="input"
                  v-model="userInfo.ship_province"
                ></has-icon-input>
              </div>
            </div>

            <!-- 用户地址 -->
            <div class="addrItem">
              <div class="left">
                <span class="name"
                  ><strong>*</strong>{{ $t("User.Address.Addr") }}：</span
                >
              </div>
              <div class="right">
                <has-icon-input
                  :height="50"
                  backgroundColor="#f6f6f6"
                  class="input"
                  v-model="userInfo.site"
                ></has-icon-input>
              </div>
            </div>
            <!-- 用户地址2 -->
            <div class="addrItem">
              <div class="left">
                <span class="name"
                  ><strong></strong>{{ $t("User.Address.Addr2") }}：</span
                >
              </div>
              <div class="right">
                <has-icon-input
                  :height="50"
                  backgroundColor="#f6f6f6"
                  class="input"
                  v-model="userInfo.ship_addr_2"
                ></has-icon-input>
              </div>
            </div>
            <!-- 邮编 -->
            <div class="addrItem">
              <div class="left">
                <span class="name"
                  ><strong>*</strong>{{ $t("User.Address.Postcode") }}：</span
                >
              </div>
              <div class="right">
                <has-icon-input
                  :height="50"
                  backgroundColor="#f6f6f6"
                  class="input"
                  v-model="userInfo.postcode"
                ></has-icon-input>
              </div>
            </div>
          </div>

          <div class="btn">
            <span
              v-if="popname == $t('Cart.Checkout.EditAddress')"
              class="setMore"
              @click="delAddr"
              >{{ $t("Common.Button.Delete") }}</span
            >
            <span
              v-if="popname == $t('Cart.Checkout.EditAddress')"
              class="userInfo"
              @click="addOrEdit('edit', addr_id)"
              >{{ $t("Common.Button.Save") }}</span
            >
            <span
              v-if="popname == $t('Cart.Checkout.AddNewAddress')"
              class="userInfo"
              @click="addOrEdit('add')"
              >{{ $t("Common.Button.Add") }}</span
            >
          </div>
        </div>
      </pop-up>
    </div>

    <div class="hasSite" v-if="hasDefAddr">
      <div class="center">
        <div
          class="siteItem"
          v-if="hasDefAddr === true || hasDefAddr === 'noDef'"
        >
          <div class="siteItemBox">
            <choose-btn
              :active="true"
              :title="chooseAddr.ship_name"
            ></choose-btn>
            <!--     @click.native="$emit('update:chooseAddr',AllAddr[0])" -->
            <div class="siteAndMobile">
              <span class="name">{{ chooseAddr.ship_name }}</span>
              <span class="site font_hide"
                >{{ chooseAddr.ship_addr }}，{{ chooseAddr.ship_zip }}</span
              >
              <span class="mobile">{{ chooseAddr.ship_mobile }}</span>
            </div>
            <div class="setDefAddr" @click="edit('edit', chooseAddr)">
              {{ $t("Common.Button.Edit") }}
            </div>
            <div
              class="setDefAddr def"
              @click="setDefAddr(chooseAddr.addr_id, 'isTop')"
              v-if="chooseAddr.default_addr != '1'"
            >
              {{ $t("User.Address.SetDefault") }}
            </div>
            <div class="isdef" v-if="chooseAddr.default_addr == '1'">
              {{ $t("Common.Pages.Default") }}
            </div>
          </div>
        </div>

        <div class="more" v-if="isOpenAddr">
          <div class="siteItem" v-for="item in AllAddr" :key="item.addr_id">
            <div
              class="siteItemBox"
              v-show="item.addr_id != chooseAddr.addr_id"
            >
              <choose-btn
                :title="item.ship_name"
                :active="chooseAddr.addr_id == item.addr_id"
                @click.native="$emit('update:chooseAddr', item)"
              ></choose-btn>
              <div class="siteAndMobile">
                <span class="name">{{ item.ship_name }}</span>
                <span class="site font_hide"
                  >{{ item.ship_addr }}，{{ item.ship_zip }}</span
                >
                <span class="mobile">{{ item.ship_mobile }}</span>
              </div>
              <div class="setDefAddr" @click="edit('edit', item)">
                {{ $t("Common.Button.Edit") }}
              </div>
              <div
                class="setDefAddr def"
                @click="setDefAddr(item.addr_id)"
                v-if="item.default_addr != '1'"
              >
                {{ $t("User.Address.SetDefault") }}
              </div>
              <div class="isdef" v-if="item.default_addr == '1'">
                {{ $t("Common.Pages.Default") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="moresite" @click="isOpenAddr = !isOpenAddr">
        {{ $t("User.Address.Pages.MoreAddress")
        }}<i
          class="iconfont"
          :class="{
            'icon-jiantou-copy-copy': !isOpenAddr,
            'icon-jiantou-copy-copy-copy': isOpenAddr,
          }"
        ></i>
      </p>
    </div>

    <p v-if="!!!hasDefAddr" class="errorHint">
      {{ $t("Cart.Checkout.Msg.NoAddress") }}
    </p>
  </div>
</template>
<script>
import ChooseBtn from "./smallTool/ChooseBtn.vue";
import PopUp from "common/popup/PopUp";
import HasIconInput from "common/input/HasIconInput";
import User from "@/model/User.js";

export default {
  name: "UserInfo",
  data() {
    return {
      //是否显示弹框
      isshowDialog: false,

      //是否展开地址列表
      isOpenAddr: false,

      //弹框的文字
      popname: this.$t("Cart.Checkout.AddNewAddress"),

      //当前正在编辑的地址id
      addr_id: "",

      //收货人信息
      userInfo: {
        name: "",
        postcode: "",
        site: "",
        mobile: "",
        default_addr: "0",
        ship_area: "",
        ship_addr_2: "", //line2
        ship_area_code: "",
        ship_province: "",
      },
    };
  },

  computed: {
    //判断是否有默认地址,返回false则无地址数据
    hasDefAddr() {
      if (this.AllAddr.length > 0) {
        if (this.AllAddr[0].default_addr == "1") {
          return true;
        } else {
          //有地址数据，但无默认
          return "noDef";
        }
      }
      return false;
    },

    defaultChoose() {
      let tem = this.userInfo.ship_area;
      if (tem) {
        return tem.split("-");
      }
    },
  },

  watch: {
    //向父组件发送当前选择的地址列表
    "chooseAddr.addr_id": {
      handler(newAdd, oldAdd) {
        if (!!!oldAdd) {
          return;
        }
        this.$emit("chooseNewAdd");
      },
      immediate: false,
    },
  },

  props: {
    //地址列表
    AllAddr: {
      type: Array,
      default: [],
    },

    //选择的地址列表
    chooseAddr: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    //点击新增收货地址
    addsite() {
      this.isshowDialog = !this.isshowDialog;
      this.userInfo = {
        name: "",
        postcode: "",
        site: "",
        mobile: "",
        default_addr: "0",
        ship_area: "",
        ship_addr_2: "",
        ship_area_code: "",
        ship_province: "",
      };
      this.popname = this.$t("Cart.Checkout.AddNewAddress");
    },

    // 改变了城市的选项
    changeCity(e) {
      this.userInfo.ship_area = e.join("-");
    },

    changeShipAreaCode(code) {
      this.userInfo.ship_area_code = code;
    },

    // 添加、编辑地址
    addOrEdit(action, addr_id) {
      //判断是否有内容为空
      let isEmpty = ((item) => {
        let filter = ["ship_area_code", "ship_addr_2"];
        for (let i in item) {
          if (filter.includes(i)) {
             continue
          }
          if (["", undefined].includes(item[i])) {
            return true;
          }
        }
        return false;
      })(this.userInfo);

      //有空则不发送请求
      if (isEmpty) {
        this.$message("error", this.$t("Passport.Validation.isEmpty"));
      } else {
        //如果之前没有添加过地址，则将首次添加的设为默认
        let default_addr;

        if (this.AllAddr.length == 0) {
          default_addr = "1";
        } else {
          default_addr = this.userInfo.default_addr;
        }
        User.Addr.Save({
          data: {
            action: action,
            addr_id: action == "add" ? 0 : addr_id,
            ship_area: this.userInfo.ship_area,
            ship_addr: this.userInfo.site,
            ship_addr_2: this.userInfo.ship_addr_2,
            ship_province: this.userInfo.ship_province,
            ship_name: this.userInfo.name,
            ship_mobile: this.userInfo.mobile,
            ship_zip: this.userInfo.postcode,
            default_addr: default_addr,
            city: "",
            province: "",
            ship_area_code: this.userInfo.ship_area_code,
          },
          succ: (res) => {
            if (action == "add") {
              // 如果没有添加过地址则需要在添加地址后请求其他数据，有则不需要
              if (this.AllAddr.length == 0) {
                this.updateAddr();
              } else {
                this.$parent.getAllAddr();
              }
            } else if (action == "edit") {
              //如果当前正在编辑的是当前已经选中的地址，那么在获取玩地址列表后应当同步更新已选择的数据
              let isChoose = "";
              if (this.chooseAddr.addr_id == this.addr_id) {
                isChoose = "isChoose";
              }
              this.$parent.getAllAddr(isChoose);
            } else {
              this.updateAddr();
            }
            // if (typeof isDef != "object") {
            this.isshowDialog = !this.isshowDialog;
            // }
          },
          fail: (res, all) => {
            this.$message("error", all.msg);
          },
        });
      }
    },

    //修改后更新数据
    async updateAddr() {
      //重新请求地址列表
      await this.$parent.getAllAddr("frist");
      await this.$parent.getShipping();
      await this.$parent.getTotal();
    },

    //设为默认  ,top参数解决当已选择的项不是默认地址，点击设为默认后该属性不会自动更新的问题
    setDefAddr(addr_id, top) {
      User.Addr.setDefAddr({
        data: {
          addr_id: addr_id,
        },
        succ: (res, all) => {
          this.$message("success", all.msg);
          if (top) {
            this.$parent.getAllAddr(top);
          } else {
            this.$parent.getAllAddr();
          }
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },

    //删除地址
    delAddr() {
      //判断当前需要删除的地址是否与选择的地址一致,如果一致则重新请求所有数据
      let isAll;
      if (this.addr_id != "" && this.addr_id == this.chooseAddr.addr_id) {
        isAll = true;
      }

      User.Addr.Delete({
        data: {
          addr_id: this.addr_id,
        },
        succ: (res, all) => {
          this.$message("success", all.msg);
          if (isAll) {
            this.updateAddr();
          } else {
            this.$parent.getAllAddr();
          }
          this.isshowDialog = !this.isshowDialog;
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },

    //点击编辑
    edit(action, data) {
      this.isshowDialog = !this.isshowDialog;
      this.popname = this.$t("Cart.Checkout.EditAddress");
      this.userInfo = {
        name: data.ship_name || "",
        postcode: data.ship_zip || "",
        site: data.ship_addr || "",
        mobile: data.ship_mobile || "",
        default_addr: data.default_addr || "",
        ship_area: data.ship_area || "",
        ship_addr_2: data.ship_addr_2 || "",
        ship_area_code: data.ship_area_code || "",
        ship_province: data.ship_province || "",
      };
      this.addr_id = data.addr_id;
    },
  },
  created() {},
  components: {
    ChooseBtn,
    PopUp,
    HasIconInput,
    CitySelection: () => import("content/citySelection"),
  },
};
</script>
<style lang='scss' scoped>
.userInfo {
  border-bottom: 1px solid var(--border-color-default);
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_buyInfo {
      font-size: 0.875rem;
      color: var(--text-color-default);
    }
    .addsite {
      cursor: pointer;
      color: var(--minor-color);
      font-size: 0.75rem;
    }

    .pop {
      // width: 100%;
      .header {
        width: 45rem;
        background-color: #f3f3f3;
        display: flex;
        justify-content: space-between;
        color: var(--text-color-minor);
        font-size: 0.875rem;
        padding: 0.8rem 0.5rem;
        align-items: center;
        i {
          cursor: pointer;
        }
      }

      .content_f {
        padding: 1rem;
        background-color: white;
        .addrItem {
          display: flex;
          padding: 0.5rem;
          .left {
            width: 110px;
            margin-right: 12px;
            .name {
              line-height: 50px;
              height: 50px;
              display: block;
              // margin-right: 1rem;
              // margin-bottom: 1rem;
              font-size: 0.75rem;
              color: var(--text-color-minor);
              strong {
                color: var(--red-color);
                margin-right: 3px;
              }
            }
          }
          .right {
            flex-grow: 1;
            .input {
              flex-grow: 1;
              padding: 0;
            }
          }
        }
      }

      .btn {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 1rem;
        padding-right: 1rem;
        span {
          background-color: var(--red-color);
          color: white;
          font-size: 0.75rem;
          padding: 0.5rem 1rem;
          border-radius: 3px;
          cursor: pointer;
          &:hover {
            opacity: 0.9;
          }
        }

        .setMore {
          margin-right: 0.75rem;
          background-color: white;
          border: 1px solid var(--border-color-default);
          color: var(--text-color-default);
          box-sizing: border-box;
        }
      }
    }
  }

  .center {
    .siteItem {
      .siteItemBox {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        font-size: 0.75rem;
        color: var(--text-color-minor);
        .siteAndMobile {
          margin-left: 1rem;
          width: 60%;
          display: flex;
          margin-right: 2rem;
          .name {
            width: 20%;
          }
          .site {
            width: 60%;
          }
          .mobile {
            margin-left: 15px;
            width: 20%;
          }
        }

        .setDefAddr {
          cursor: pointer;
          &:hover {
            opacity: 0.9;
          }
        }

        .def {
          margin-left: 1.5rem;
          color: var(--red-color);
        }

        .isdef {
          margin-left: 1.5rem;
          width: 3rem;
          height: 1.25rem;
          background-color: var(--red-color);
          color: white;
          text-align: center;
          line-height: 1.25rem;
          border-radius: 0.2rem;
        }
      }
    }
  }

  .moresite {
    margin: 1rem 0;
    font-size: 0.75rem;
    color: var(--text-color-minor);
    cursor: pointer;
    display: inline-block;
    &:hover {
      opacity: 0.9;
    }
  }

  .errorHint {
    font-size: 0.875rem;
    color: var(--text-color-minor);
    line-height: 3.25;
  }
}
</style>