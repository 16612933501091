<template>
  <div class="delivery">
    <h2 class="title_buyInfo">{{$t('Cart.Checkout.Validation.SelectShipMethod')}}</h2>

    <div class="btn">
      <choose-btn
        v-for="(item, index) in shipping"
        :key="item.st_id"
        :active="index == chooseDelivery"
        :title="$cl('st_name',item,$i18n.locale)"
        @click.native="chooseShipping(index)"
      ></choose-btn>
      <!-- <i class="iconfont icon-jiantou_yemian_xiangyou"></i> -->
      <p class="more" v-if="shipping.length==0">
        {{$t('Cart.Checkout.Msg.NoAdress')}}
      </p>
    </div>
  </div>
</template>
<script>
import ChooseBtn from "./smallTool/ChooseBtn.vue";
export default {
  name:'Delivery',
  data() {
    return {
      //当前选择的配送方式
      chooseDelivery: 0,
    };
  },
  props:{
    //配送列表
     shipping:{
       type:Array
     }
  },

  methods:{
    chooseShipping(index){
      this.chooseDelivery = index
      this.$emit('chooseShipping',index)
    },

    //当地址发生改变，配送方式也发生改变，此时的选择方式需要修改,默认修改为0
    change(){
       this.chooseDelivery = 0
    }

  },


  created() {},
  components: {
    ChooseBtn,
  },
};
</script>
<style lang='scss' scoped>

.delivery {
  font-size: 0.75rem;
  color: var(--text-color-minor);
  padding-top: 0.875rem;
  border-bottom: 1px solid var(--border-color-default);
  .title_buyInfo {
    font-size: 0.875rem;
    color: var(--text-color-default);
  }
  .btn {
    display: flex;
    align-items: center;
    margin-top: 0.875rem;
    margin-bottom: 1.5rem;
    & > :nth-child(2) {
      margin: 0 1rem;
    }
  }

  .more {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>