<template>
  <div class="choose" :style="{ width: width }" :class="{ active: active }">
    <p class="title">{{ title }}</p>
    <i class="icon iconfont icon-in_gouxuan">
    </i>
  </div>
</template>
<script>
export default {
  data() {
    return {
        
    };
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    width: {
      type: String,
      default: "100px",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
};
</script>
<style lang='scss' scoped>

.choose {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border: 1px solid var(--border-color-default);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  .title {
    font-size: 0.75rem;
    color: var(--text-color-minor);
    line-height: 2;
  }

  .icon {
    display: none;
  }
  &:hover {
    border: 1px solid var(--red-color);
  }
}

.active {
  background-color: white;
  border: 1px solid var(--red-color);
  .title {
    color:var(--text-color-minor);
  }
  .icon {
      display: block;
    position: absolute;
    bottom: 0;
    right: -1px;
    font-size:1.5rem;
    color:var(--red-color);

   
  }
}
</style>